import {
  faAdd,
  faCheckCircle,
  faEdit,
  faEye,
  faFilterCircleXmark,
  faHouse,
  faShower,
  faTrash,
  faUndoAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Modal, FormControl, Form } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { TailSpin } from "react-loader-spinner";
import { toast } from "react-toastify";
import { file_url, organisme_API } from "../../../service/config";
import InputMask from "react-input-mask";
import { Image } from "primereact/image";
import { Tag } from "primereact/tag";
import ChangerStatusAutoEcole from "./ChangerStatusAutoEcole";
import isconnected from "../../../service/isconnected";
import SelectOne from "../../../outils/Select/selectCustom";
import { regions } from "../../../service/auth/UtilApi";
import SelectCustom from "../../../outils/Select/SelectComponent";
import { useSelector } from "react-redux";
import * as yup from "yup";
import moment from "moment";

export default function Auto_Ecole() {
  const dataRedux = useSelector((state) => state.categ);
  const abortController = new AbortController();
  const schema = yup.object().shape({
    nom_org: yup.string().required("Ce champs est réquis !"),
    email_org: yup
      .string()
      .email("adresse email invalide !")
      .required("Ce champs est réquis !"),
    adresse_org: yup.string().required("Ce champs est réquis !"),
    ville_org: yup.string().required("Ce champs est réquis !"),
    province_org: yup.string().required("Ce champs est réquis !"),
    cp_org: yup.string().required("Ce champs est réquis !"),
    pays_org: yup.string().required("Ce champs est réquis !"),
    web_org: yup.string(),
    tel_org: yup.string().required("Ce champs est réquis !"),
    scan_agrement: yup.string().required("Ce champs est réquis !"),
    numero_agrement: yup.string().required("Ce champs est réquis !"),
    logo_org: yup.string().required("Ce champs est réquis !"),
    statut_auto: yup.string().required("Ce champs est réquis !"),
    nif_auto: yup.string().required("Ce champs est réquis !"),
    stat_auto: yup.string().required("Ce champs est réquis !"),
    rcs_auto: yup.string().required("Ce champs est réquis !"),
    delais_agrement: yup.date().required("Ce champs est réquis !"),
    categ_permis: yup.string().required("Ce champs est réquis !"),
  });
  const [filtre, setfiltre] = useState({
    nom: "",
    region: "",
    ville: "",
    type: "",
    categ_permis: "",
  });
  const [errorfiltre, seterrorfiltre] = useState({
    nom: "",
    region: "",
    ville: "",
    type: "",
    categ_permis: "",
  });
  const [videfiltre, setvidefiltre] = useState({
    nom: "",
    region: "",
    ville: "",
    type: "",
    categ_permis: "",
  });
  const handleFilter = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    setfiltre({ ...filtre, [name]: value });
  };
  const permis = (perm) => {
    let beforSplt = perm?.split(",");
    beforSplt?.pop();
    if (perm) {
      return (
        <div className="d-flex gap-1 flex-wrap">
          {beforSplt.map((el, i) => (
            <span className="badge bg-cyan">{el}</span>
          ))}
        </div>
      );
    } else {
      return "Aucun permis !";
    }
  };
  const columns = [
    {
      name: "Num.Ag",
      selector: (row) => row.numero_agrement,
      sortable: true,
    },
    {
      name: "logo",
      selector: (row) => (
        <Image
          style={{ maxHeight: "25px" }}
          src={file_url + row.logo_org}
          alt="Image"
          width="30"
          preview
        />
      ),
      sortable: true,
    },
    {
      name: "nom",
      selector: (row) => row.nom_org,
      sortable: true,
      width: "18%",
      wrap: true,
    },
    {
      name: "Catégories",
      selector: (row) => permis(row.categ_permis),
      sortable: true,
      width: "18%",
      wrap: true,
    },
    {
      width: "18%",
      name: "email",
      selector: (row) => row.email_org,
      sortable: true,
    },
    {
      width: "18%",
      name: "Téléphone",
      selector: (row) => row.tel_org,
      sortable: true,
    },
    {
      width: "150px",
      name: "province",
      selector: (row) => row.province_org,
      sortable: true,
    },
    // {
    //   name: "Scan agrement",
    //   selector: (row) => (
    //     <Image
    //       src={file_url + row.scan_agrement}
    //       alt="Image"
    //       width="30"
    //       preview
    //     />
    //   ),
    //   sortable: true,
    // },
    {
      width: "115px",
      name: "statut",
      selector: (row) => (
        <ChangerStatusAutoEcole row={row} setrecharger={setrecharger} />
      ),
      sortable: true,
    },
    {
      width: "145px",
      name: "Date d'inscription",
      selector: (row) => moment(row.created_at).format("DD/MM/yyyy"),
      sortable: true,
    },
    {
      name: "Détails",
      cell: (row) => (
        <div className="d-flex gap-1">
          <button
            className="btn btn-primary"
            onClick={() => {
              // alert(JSON.stringify(row))
              setmode(true);
              setFormValues(row);
              setShow(true);
              setImage(`${file_url}/${row.logo_org}`);
              setImagescan(`${file_url}/${row.scan_agrement}`);
            }}
          >
            <FontAwesomeIcon icon={faEye} />
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const [data, setdata] = useState([]);
  const [show, setShow] = useState(false);
  const [mode, setmode] = useState(false);
  const [supp, setsupp] = useState(false);
  const [recharger, setrecharger] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [load, setLoad] = useState(false);
  const [formValues, setFormValues] = useState({
    nom_org: "",
    email_org: "",
    adresse_org: "",
    ville_org: "",
    province_org: "",
    cp_org: "",
    pays_org: "",
    web_org: "",
    tel_org: "",
    logo_org: null,
    statut_auto: "",
    nif_auto: "",
    stat_auto: "",
    rcs_auto: "",
    numero_agrement: "",
    delais_agrement: "",
    scan_agrement: null,
    categ_permis: "",
  });
  const [vide, setvide] = useState({
    nom_org: "",
    email_org: "",
    adresse_org: "",
    ville_org: "",
    province_org: "",
    cp_org: "",
    pays_org: "",
    web_org: "",
    tel_org: "",
    scan_agrement: null,
    numero_agrement: "",
    logo_org: null,
    statut_auto: "",
    nif_auto: "",
    stat_auto: "",
    rcs_auto: "",
    delais_agrement: "",
    categ_permis: "",
  });

  const [toggledClearRows, settoggledClearRows] = useState(false);
  const handleClearRows = () => {
    settoggledClearRows(!toggledClearRows);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handlesup = () => setsupp(!supp);
  const [error, setError] = useState([]);
  const handleChange = ({ currentTarget }) => {
    const { name, value } = currentTarget;
    if (isconnected.user().type_util == "MTM") {
      setFormValues({ ...formValues, [name]: value });
      setError({ ...error, [name]: null });
    }
  };

  const [image, setImage] = useState(null);
  const [imagescan, setImagescan] = useState(null);

  const onImageChange = (e) => {
    console.log(e.target.files[0].size);
    if (isconnected.user().type_util == "MTM") {
      if (e.target.files && e.target.files[0]) {
        setFormValues({ ...formValues, [e.target.name]: e.target.files[0] });
        setError({ ...error, [e.target.name]: null });
      }
      if (e.target.name === "logo_org") {
        setImage(URL.createObjectURL(e.target.files[0]));
      } else {
        setImagescan(URL.createObjectURL(e.target.files[0]));
      }
    }
  };

  const verif = async () => {
    setError({});
    const champs = Object.keys(formValues);
    for (let i = 0; i < champs.length; i++) {
      const name_champs = champs[i];
      try {
        await schema
          .validateAt(name_champs, formValues, { abortEarly: true })
          .then(() => {
            if (name_champs == "categ_permis") {
              handleSubmit();
            }
          });
      } catch (error) {
        console.log(error.path);
        if (error.path === name_champs) {
          setError({ [name_champs]: error.message });
          return;
        }
      }
    }
  };

  const handleSubmit = () => {
    console.log(formValues);
    formValues.type_org = "Auto-écoles";
    setLoad(true);
    const apiErrors = {};
    axios
      .post(organisme_API, formValues, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.log(response);
        setLoad(false);
        setShow(false);
        toast.success("enregistrement reussi !");
        getListAuto();
        setImage(null);
        setFormValues(vide);
      })
      .catch((error) => {
        const msg = error.response.data;
        setLoad(false);
        if (msg) {
          toast.error("Verifier votre saisi !");
        }
        if (error.response.status == 500) {
          toast.error(error.response.message);
          getListAuto();
          setShow(false);
          setFormValues(vide);
          setImage(null);
        }
      });
    setError(apiErrors);
  };

  const handleDelete = async () => {
    let i = 0;
    for (const element of selectedRows) {
      i++;
      // eslint-disable-next-line no-loop-func
      await axios
        .delete(organisme_API + "/" + element.id_org)
        .then(() => {
          if (i === selectedRows.length) {
            toast.success("Suppression reussi !");
            settoggledClearRows(!toggledClearRows);
            setsupp(false);
          }
        })
        .catch((e) => {
          if (e.response.status == 401 && i === selectedRows.length) {
            settoggledClearRows(!toggledClearRows);
            toast.warn(e.response.data.message);
            setsupp(false);
          }
        });
    }
  };
  const handleUpdate = () => {
    if (typeof formValues.logo_org === "string") {
      const { logo_org, ...formfiltrer } = formValues;
      setFormValues(formfiltrer);
    } else {
      // alert(JSON.stringify(formValues))
      setLoad(true);
      const apiErrors = {};
      axios
        .post(organisme_API + `/${formValues.id_org}?_method=PUT`, formValues, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response);
          setLoad(false);
          setShow(false);
          toast.success("Modification reussi !");
          getListAuto();
          setImage(null);
          setFormValues(vide);
        })
        .catch((error) => {
          const msg = error.response.data;
          setLoad(false);
          if (msg) {
            toast.error("Verifier votre saisi !");
            let champs = Object.keys(msg);
            for (let i = 0; i < champs.length; i++) {
              const element = champs[i];
              if (msg[element]) {
                apiErrors[element] = msg[element];
              }
            }
          }
        });
      setError(apiErrors);
    }
  };

  const getAllOrganisme = () => {
    setLoad(true);
    axios
      .get(organisme_API + "?order=asc&limit=4", {
        signal: abortController.signal,
      })
      .then((rep) => {
        console.log(rep);
        setdata(rep.data.data);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getListAuto = async () => {
    setLoad(true);
    try {
      await axios
        .get(organisme_API + "?type_org=Auto-écoles", {
          signal: abortController.signal,
        })
        .then((reponse) => {
          console.log(reponse.data);
          setdata(reponse.data.data);
          setLoad(false);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getListAuto();
    settoggledClearRows(!toggledClearRows);
    return () => {
      abortController.abort();
    };
  }, [recharger]);
  return (
    <div className="container-lg container-fluid py-4">
      <Modal
        show={supp}
        onHide={handlesup}
        backdrop="static"
        keyboard={false}
        fullscreen={false}
        size={"sm"}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h2>Supprimer</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>Voulez-vous vraiment supprimer ?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handlesup}>
            Annuler
          </Button>
          <Button
            variant="danger"
            disabled={load}
            onClick={() => handleDelete()}
          >
            Supprimer
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="mb-3 d-flex flex-row align-items-center justify-content-between">
        <div className="">
          <h2>
            <FontAwesomeIcon icon={faHouse} className="me-1" /> Auto-école{" "}
          </h2>
        </div>
        <div>
          <Button
            variant="primary"
            onClick={() => {
              setmode(false);
              setFormValues(vide);
              setImage(null);
              setImagescan(null);
              handleShow();
            }}
          >
            <FontAwesomeIcon icon={faAdd} className="me-2" />
            Nouvel auto-école
          </Button>
        </div>
      </div>

      <div className="">
        <div className="row my-2 mb-3 g-3">
          <div className="col-lg-4 col-md-6  d-flex align-items-center">
            <label style={{ width: "65px" }}>Nom :</label>
            <input
              className="form-control"
              name="nom"
              value={filtre.nom}
              onChange={handleFilter}
              type="text"
              placeholder="nom de l'auto-école"
            />
          </div>
          <div className="col-lg-4 col-md-6  d-flex align-items-center">
            <label style={{ width: "65px" }}>Région :</label>
            <select
              className="form-control"
              name="region"
              value={filtre.region}
              onChange={handleFilter}
            >
              <option value="">sélectionner ici...</option>
              {regions.map((reg) => (
                <option value={reg.label}>{reg.label}</option>
              ))}
            </select>
          </div>
          <div className="col-lg-4 col-md-6  d-flex align-items-center">
            <label style={{ width: "65px" }}>Ville :</label>
            <input
              className="form-control"
              name="ville"
              value={filtre.ville}
              placeholder="filtrer par ville"
              onChange={handleFilter}
            />
          </div>
          <div className="col-lg-4  d-flex align-items-center">
            <label style={{ width: "100px" }}>Catégories :</label>
            <div className="w-75">
              <SelectCustom
                options={dataRedux}
                load={false}
                choix={filtre?.categ_permis}
                placeholder="filtre par catégories"
                setFormValues={setfiltre}
                formValues={filtre}
                setError={seterrorfiltre}
                error={errorfiltre}
                name={"categ_permis"}
              />
            </div>
          </div>
          <div className="col">
            <div className="d-flex justify-content-end mb-2">
              <Button
                variant="secondary"
                onClick={() => setfiltre(videfiltre)}
                size="md"
              >
                <FontAwesomeIcon icon={faFilterCircleXmark} className="me-2" />{" "}
                Enlever filtre
              </Button>
            </div>
          </div>
        </div>

        <DataTable
          className="custom-table bg-white "
          responsive
          style={{ width: "auto" }}
          title={<p className="fs-4">Liste des auto-écoles</p>}
          columns={columns}
          data={data
            .filter((f) =>
              filtre.nom != "" && f.nom_org != null
                ? f.nom_org.toLowerCase().includes(filtre.nom.toLowerCase())
                : f
            )
            .filter((f) =>
              filtre.region != "" && f.province_org != null
                ? f.province_org
                    .toLowerCase()
                    .includes(filtre.region.toLowerCase())
                : f
            )
            .filter((f) =>
              filtre.categ_permis != "" && f.categ_permis != null
                ? f.categ_permis
                    .toLowerCase()
                    .includes(filtre.categ_permis.toLowerCase())
                : f
            )
            .filter((f) =>
              filtre.ville != "" && f.ville_org != null
                ? f.ville_org.toLowerCase().includes(filtre.ville.toLowerCase())
                : f
            )}
          selectableRows
          clearSelectedRows={toggledClearRows}
          selectedRow={selectedRows}
          progressPending={load}
          contextActions={
            <div className="d-flex w-100 justify-content-between">
              <Button
                className="me-2"
                variant="secondary"
                onClick={() => {
                  settoggledClearRows(!toggledClearRows);
                }}
              >
                <FontAwesomeIcon icon={faUndoAlt} className="" />
              </Button>

              <button
                className="btn btn-danger me-2"
                onClick={() => {
                  setsupp(true);
                }}
              >
                <FontAwesomeIcon icon={faTrash} />
              </button>
            </div>
          }
          progressComponent={
            <div className="w-100 d-flex justify-content-center py-5">
              <TailSpin
                height="80"
                width="80"
                color="#22577A"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          }
          contextMessage={{
            singular: "élement",
            plural: "élements",
            message: "sélectionnées",
          }}
          onSelectedRowsChange={(rows) => {
            setSelectedRows(rows.selectedRows);
          }}
          pagination
          paginationPerPage={5}
          paginationComponentOptions={{
            rowsPerPageText: "Lignes par page",
          }}
          paginationRowsPerPageOptions={[5, 10, 15, 20]}
        />
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size={"xl"}
        fullscreen
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h2>Formulaire </h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12 d-flex align-item-stretch">
              <Form className="w-100" onSubmit={verif}>
                <div className="row">
                  <div className="col-12 col-lg-6 d-flex align-items-stretch">
                    <div className="card w-100">
                      <div className="card-body">
                        {mode && (
                          <div className="col-12 pb-3">
                            <Image
                              src={image}
                              alt="Image"
                              width="120"
                              preview
                              
                            />
                          </div>
                        )}
                        <div className="col-12">
                          <label className="form-label">Nom</label>
                          <input
                            className={
                              "form-control " + (error.nom_org && "is-invalid")
                            }
                            name="nom_org"
                            value={formValues.nom_org}
                            onChange={handleChange}
                            type="text"
                            placeholder="Saisir nom "
                          />
                          {error.nom_org ? (
                            <div className="invalid-feedback">
                              {error.nom_org}
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="my-3">
                          <label className="form-label">Email</label>
                          <input
                            className={
                              "form-control " +
                              (error.email_org && "is-invalid")
                            }
                            name="email_org"
                            value={formValues.email_org}
                            onChange={handleChange}
                            type="email"
                            placeholder="Saisir email"
                          />
                          {error.email_org ? (
                            <div className="invalid-feedback">
                              {error.email_org}
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="row g-3">
                          <div className="col-12 col-lg-6">
                            <label className="form-label">Adresse</label>
                            <input
                              className={
                                "form-control " +
                                (error.adresse_org && "is-invalid")
                              }
                              name="adresse_org"
                              value={formValues.adresse_org}
                              onChange={handleChange}
                              type="text"
                              placeholder="Saisir adresse"
                            />
                            {error.adresse_org ? (
                              <div className="invalid-feedback">
                                {error.adresse_org}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="col-12 col-lg-6">
                            <label className="form-label">ville</label>
                            <input
                              className={
                                "form-control " +
                                (error.ville_org && "is-invalid")
                              }
                              name="ville_org"
                              value={formValues.ville_org}
                              onChange={handleChange}
                              type="text"
                              placeholder="Saisir ville"
                            />
                            {error.ville_org ? (
                              <div className="invalid-feedback">
                                {error.ville_org}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="col-12 col-lg-6">
                            <label className="form-label">Région</label>
                            <SelectOne
                              options={regions}
                              load={false}
                              choix={formValues.province_org}
                              placeholder="choix région"
                              setFormValues={setFormValues}
                              formValues={formValues}
                              setError={setError}
                              error={error}
                              name={"province_org"}
                            />
                            {error.province_org ? (
                              <div className="invalid-feedback">
                                {error.province_org}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="col-12 col-lg-6">
                            <label className="form-label">Code postal</label>
                            <input
                              className={
                                "form-control " + (error.cp_org && "is-invalid")
                              }
                              name="cp_org"
                              value={formValues.cp_org}
                              onChange={handleChange}
                              type="text"
                              placeholder="Saisir code postal"
                            />
                            {error.cp_org ? (
                              <div className="invalid-feedback">
                                {error.cp_org}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="col-12">
                            <label className="form-label">Pays</label>
                            <input
                              className={
                                "form-control " +
                                (error.pays_org && "is-invalid")
                              }
                              name="pays_org"
                              value={formValues.pays_org}
                              onChange={handleChange}
                              type="text"
                              placeholder="Saisir pays"
                            />
                            {error.pays_org ? (
                              <div className="invalid-feedback">
                                {error.pays_org}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="col-12">
                            <label className="form-label">Site web</label>
                            <input
                              className={
                                "form-control " +
                                (error.web_org && "is-invalid")
                              }
                              name="web_org"
                              value={formValues.web_org}
                              onChange={handleChange}
                              type="text"
                              placeholder="Saisir Site web"
                            />
                            {error.web_org ? (
                              <div className="invalid-feedback">
                                {error.web_org}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="col-12">
                            <label className="form-label">Telephone (*)</label>
                            <InputMask
                              onKeyUp={(e) => {
                                console.log(e.target.value);
                                if (e.target.value === "") {
                                  setError({
                                    ...error,
                                    tel_org: ["le champs telephone est requis"],
                                  });
                                } else if (e.target.value !== "") {
                                  setError({ ...error, tel_org: [] });
                                }
                              }}
                              onChange={handleChange}
                              mask="+261 39 99 999 99"
                              type="text"
                              className={
                                "form-control " +
                                (error.tel_org?.length > 0 && " is-invalid")
                              }
                              value={formValues.tel_org}
                              name="tel_org"
                              placeholder="Telephone"
                            />

                            {error.tel_org && (
                              <p className="invalid-feedback p-0 m-1">
                                {error.tel_org}
                              </p>
                            )}
                          </div>
                          <div className="col-12">
                            <div className="row">
                              <div className="col-6">
                                <label className="form-label">Logo </label>
                                <input
                                  className={
                                    "form-control " +
                                    (error.logo_org && "is-invalid")
                                  }
                                  name="logo_org"
                                  id="logo_org"
                                  type="file"
                                  onChange={onImageChange}
                                  accept="image/*"
                                />
                                {error.logo_org && (
                                  <div className="invalid-feedback">
                                    {error.logo_org}
                                  </div>
                                )}
                              </div>
                              <div className="col-6 d-flex align-items-end">
                                <Image
                                  src={image}
                                  alt="Image"
                                  width="40"
                                  preview
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6 d-flex align-items-stretch">
                    <div className="card w-100">
                      <div className="card-body">
                        <div className="row g-3 align-items-center">
                          <div className="col-12">
                            <label className="form-label">
                              Statut auto-école
                            </label>
                            <select
                              className={
                                "form-control " +
                                (error.statut_auto && "is-invalid")
                              }
                              name="statut_auto"
                              value={formValues.statut_auto}
                              onChange={handleChange}
                            >
                              <option value="">
                                Choisir parmi cette liste
                              </option>
                              <option value="Société">Société</option>
                              <option value="Entreprise Individuelle">
                                Entreprise Individuelle
                              </option>
                            </select>
                            {error.statut_auto ? (
                              <div className="invalid-feedback">
                                {error.statut_auto}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="col-8">
                            <label className="form-label">
                              Numéro d'identification fiscale (NIF)
                            </label>
                            <input
                              className={
                                "form-control " +
                                (error.nif_auto && "is-invalid")
                              }
                              name="nif_auto"
                              value={formValues.nif_auto}
                              onChange={handleChange}
                              type="text"
                              placeholder="Saisir votre Nif"
                            />
                            {error.nif_auto ? (
                              <div className="invalid-feedback">
                                {error.nif_auto}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="col-4">
                            <label className="form-label">STAT</label>
                            <input
                              className={
                                "form-control " +
                                (error.stat_auto && "is-invalid")
                              }
                              name="stat_auto"
                              value={formValues.stat_auto}
                              onChange={handleChange}
                              type="text"
                              placeholder="Saisir votre stat"
                            />
                            {error.stat_auto ? (
                              <div className="invalid-feedback">
                                {error.stat_auto}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="col-12">
                            <label className="form-label">
                              Registre du Commerce et des Sociétés (RCS)
                            </label>
                            <input
                              className={
                                "form-control " +
                                (error.rcs_auto && "is-invalid")
                              }
                              name="rcs_auto"
                              value={formValues.rcs_auto}
                              onChange={handleChange}
                              type="text"
                              placeholder="Saisir votre rcs"
                            />
                            {error.rcs_auto ? (
                              <div className="invalid-feedback">
                                {error.rcs_auto}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="col-12">
                            <label className="form-label">
                              Numéro agrément
                            </label>
                            <input
                              className={
                                "form-control " +
                                (error.numero_agrement && "is-invalid")
                              }
                              name="numero_agrement"
                              value={formValues.numero_agrement}
                              onChange={handleChange}
                              type="text"
                              placeholder="Saisir Numéro agrément"
                            />
                            {error.numero_agrement ? (
                              <div className="invalid-feedback">
                                {error.numero_agrement}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="col-12">
                            <label className="form-label">
                              Délai d'agrément
                            </label>
                            <input
                              className={
                                "form-control " +
                                (error.delais_agrement && "is-invalid")
                              }
                              name="delais_agrement"
                              value={formValues.delais_agrement}
                              onChange={handleChange}
                              type="date"
                              placeholder="le délai d'agrément"
                            />
                            {error.delais_agrement ? (
                              <div className="invalid-feedback">
                                {error.delais_agrement}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>

                          <div className="col-12">
                            <div className="row">
                              <div className="col-6">
                                <label className="form-label">
                                  Scan agrément{" "}
                                </label>
                                <input
                                  className={
                                    "form-control " +
                                    (error.scan_agrement && "is-invalid")
                                  }
                                  name="scan_agrement"
                                  id="scan_agrement"
                                  type="file"
                                  onChange={onImageChange}
                                  accept="image/*"
                                />
                                {error.scan_agrement && (
                                  <div className="invalid-feedback">
                                    {error.scan_agrement}
                                  </div>
                                )}
                              </div>
                              <div className="col-6">
                                <Image
                                  src={imagescan}
                                  alt="Image"
                                  width="70"
                                  preview
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-12">
                            <label className="form-label">
                              Catégorie permis
                            </label>
                            <SelectCustom
                              options={dataRedux}
                              load={false}
                              choix={formValues.categ_permis}
                              placeholder="choix catégories"
                              setFormValues={setFormValues}
                              formValues={formValues}
                              setError={setError}
                              error={error}
                              name={"categ_permis"}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fermer
          </Button>
          {mode == false && (
            <Button variant="primary" disabled={load} onClick={() => verif()}>
              {<>Enregistrer</>}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}
