import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  CATEGORIE_API,
  membre_API,
  organisme_API,
  Register_API,
} from "../../service/config";
import { toast } from "react-toastify";
import mtm from "../../assets/image/mtm.png";
import VerifyEmail from "./VerifyEmail";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faHomeAlt,
} from "@fortawesome/free-solid-svg-icons";
import InputMask from "react-input-mask";
import { Button, Form } from "react-bootstrap";
import Select from "react-select";
import { regions } from "../../service/auth/UtilApi";
import makeAnimated from "react-select/animated";
import Examinateur_Sign from "./Examinateur_Sign";
import ConfirmationAnnulation from "./ConfirmationAnnulation";
import AutoEcole_Sign from "./AutoEcole_Sign";
import Moniteur_Sign from "./Moniteur_Sign";
import receive from "./main.png";
import { store } from "../../service/Redux/store";
import { useSelector } from "react-redux";
import Cim_Sign from "./Cim_Sign";
import { Pays } from "../../outils/Pays";

export default function Inscription() {
  const animatedComponents = makeAnimated();
  const dataRedux = useSelector(state => state.auto)
  const [options, setOptions] = useState([]);
  const [load, setLoad] = useState(false);
  const [ito, setIto] = useState([]);
  const [stepOne, setStepOne] = useState(false);
  const [listorg, setlistorg] = useState([]);
  const [showdp, setshowdp] = useState(false);
  const [verify, setverify] = useState(false);
  const [loadcateg, setloadcateg] = useState(false);
  const navigate = useNavigate();
  const [util, setUtil] = useState({
    nom_util: "",
    prenom_util: "",
    email: "",
    tel_util: "",
    password: "",
    password_confirmation: "",
  });
  // const [error, setError] = useState([]);
  const [success, setsuccess] = useState([]);

  const [formValues, setFormValues] = useState({
    nom_org: "",
    email_org: "",
    adresse_org: "",
    ville_org: "",
    province_org: "",
    cp_org: "",
    pays_org: "",
    web_org: "",
    tel_org: "",
    type_org: "",
    delais_agrement: "",
    scan_agrement: null,
    numero_agrement: "",
    logo_org: null,
  });
  const [vide, setvide] = useState({
    nom_org: "",
    email_org: "",
    adresse_org: "",
    ville_org: "",
    province_org: "",
    cp_org: "",
    pays_org: "",
    web_org: "",
    tel_org: "",
    type_org: "",
    scan_agrement: null,
    numero_agrement: "",
    delais_agrement: "",
    logo_org: null,
  });
  const [setTest,Test] = useState([]);
  const getAutoecole = () => {
    try {
      axios
        .get(organisme_API + "?type_org=Auto-écoles")
        .then((reponse) => {
          const auto = reponse.data.data.map((element) => {
            const { nom_org = '', id_org = '' } = element;
            return { label: nom_org, value: id_org };
          });
          console.log(auto);
          store.dispatch({type:'addAuto',payload:auto})
        })
        .catch((err) => {
          console.log(err)
          toast.error("erreur lors de la récuperation liste auto-école");
        });
    } catch (error) {
      console.log(error)
      toast.error("erreur interne");
    }
  };
  const handleVide = () => {
    setFormValues(vide);
    setStepOne(false);
  };
  const change = (choice) => {
    setIto(choice);
    let text = "";
    var nb = 0;
    text = "";
    choice.forEach((element) => {
      nb++;
      text += element.label + ",";
      if (nb === choice.length) {
        setFormValues({ ...formValues, categ_permis: text });
        setError({ ...error, categ_permis: null });
      }
    });
  };
  const [error, setError] = useState([]);
  
  const [step, setStep] = useState(1);
  const handleChange = ({ currentTarget }) => {
    const { name, value } = currentTarget;
    setFormValues({ ...formValues, [name]: value });
    setError({ ...error, [name]: null });
    if (name === "type_org") {
      if (value) {
        setStepOne(true);
      } else {
        setStepOne(false);
      }
    }
  };

  const [image, setImage] = useState(null);
 
  const getAllCateg = async () => {
    setloadcateg(true);
    let getCateg = [];
    await axios
      .get(CATEGORIE_API)
      .then((rep) => {
        console.log(rep);
        const data = rep.data.data;

        data.forEach((element) => {
          getCateg.push({ value: element.id_categ, label: element.categ });
        });
        setloadcateg(false);
      })
      .catch((err) => {
        console.log(err);
        setloadcateg(false);
      });
    setOptions(getCateg);
  };
  const onImageChange = (e) => {
    console.log(e.target.files[0].size);

    if (e.target.files && e.target.files[0]) {
      setFormValues({ ...formValues, [e.target.name]: e.target.files[0] });
      setError({ ...error, [e.target.name]: null });
    }
  };

  const percent = (step / 3) * 100;
  const verif = () => {
    if (step === 1) {
      if (!formValues.nom_org) {
        setError({ ...error, nom_org: "ce champs est réquis !" });
        const input = document.getElementsByName("nom_org")[0];
        const inputTop = input.offsetTop;
        window.scrollTo(0, inputTop);
        setLoad(false);
      } else if (!formValues.email_org) {
        setError({ ...error, email_org: "ce champs est réquis !" });
        const input = document.getElementsByName("email_org")[0];
        const inputTop = input.offsetTop;
        window.scrollTo(0, inputTop);
        setLoad(false);
      } else if (!formValues.adresse_org) {
        setError({ ...error, adresse_org: "ce champs est réquis !" });
        const input = document.getElementsByName("adresse_org")[0];
        const inputTop = input.offsetTop;
        window.scrollTo(0, inputTop);
        setLoad(false);
      } else if (!formValues.ville_org) {
        setError({ ...error, ville_org: "ce champs est réquis !" });
        const input = document.getElementsByName("ville_org")[0];
        const inputTop = input.offsetTop;
        window.scrollTo(0, inputTop);
        setLoad(false);
      } else if (!formValues.province_org) {
        setError({ ...error, province_org: "ce champs est réquis !" });
        setLoad(false);
      } else if (!formValues.cp_org) {
        setError({ ...error, cp_org: "ce champs est réquis !" });
        const input = document.getElementsByName("cp_org")[0];
        const inputTop = input.offsetTop;
        window.scrollTo(0, inputTop);
        setLoad(false);
      } else if (!formValues.pays_org) {
        setError({ ...error, pays_org: "ce champs est réquis !" });
        const input = document.getElementsByName("pays_org")[0];
        const inputTop = input.offsetTop;
        window.scrollTo(0, inputTop);
        setLoad(false);
      } else {
        setStep(step + 1);
      }
    } else {
      setStep(step + 1);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoad(true);
    const apiErrors = {};
    try {
      axios
        .post(organisme_API, formValues, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response);
          setLoad(false);
          toast.success("enregistrement reussi !");
          navigate("/login");
          setImage(null);
          setFormValues(vide);
        })
        .catch((error) => {
          console.log(error);
          const msg = error.response.data;
          setLoad(false);
          if (msg) {
            toast.error("Verifier votre saisi !");
            apiErrors.nom_org = msg.nom_org;
            apiErrors.email_org = msg.email_org;
            apiErrors.adresse_org = msg.adresse_org;
            apiErrors.ville_org = msg.ville_org;
            apiErrors.province_org = msg.province_org;
            apiErrors.cp_org = msg.cp_org;
            apiErrors.pays_org = msg.pays_org;
            apiErrors.web_org = msg.web_org;
            apiErrors.tel_org = msg.tel_org;
            apiErrors.type_org = msg.type_org;
            apiErrors.scan_agrement = msg.scan_agrement;
            apiErrors.numero_agrement = msg.numero_agrement;
            apiErrors.logo_org = msg.logo_org;
          }
          if (error.response.status == 500) {
            toast.error(error.response.data.message);
          }
          if (error.response.status == 401) {
            toast.info(error.response.data.message);
          }
        });
    } catch (error) {
      console.log(error);
    }
    setError(apiErrors);
  };
  const getAllOrganisme = async () => {
    try {
      await axios
        .get(organisme_API + "?fields=id_org,nom_org")
        .then((reponse) => {
          console.log(reponse.data);
          let dataa = reponse.data.data;
          setlistorg(dataa);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    // getAllOrganisme();
    getAutoecole();
    // getAllCateg();
  }, []);

  return (
    <div className="hero-sec container-fluid ">
      <div className="row align-items-center justify-content-center" style={{minHeight:'100vh'}}>
        <div
          className=" col-12 d-flex flex-column align-items-center justify-content-center py-4"
          style={{ maxWidth: "1100px" }}
        >
          <div className="card w-100 p-0 bg-white rounded-4" style={{zIndex:'9',overflow:'hidden'}}>
           <div className="row w-100 ">
            <div className="col-4" style={{backgroundColor:"#DFD3DD",backgroundImage:'url('+receive+')',backgroundSize:'cover',backgroundRepeat:'no-repeat',backgroundPosition:'center'}}>

            </div>
            <div className="col-lg-8 col-md-8 col-12 p-4">
              <div className="text-center">
                <div>
                  <img
                    alt=""
                    src={mtm}
                    style={{ width: "50px", height: "auto" }}
                  />
                </div>

                {formValues.type_org ? (
                  <h3 className="text-center mt-2">
                    Création de compte :{" "}
                    {formValues.type_org === "Examinateurs"
                      ? "Centre d'examen"
                      : formValues.type_org == "examinateur_agent"
                      ? "Examinateur"
                      : formValues.type_org}
                  </h3>
                ) : (
                  <h3 className="text-center pb-2 mb-2">Création de compte</h3>
                )}
                {!stepOne && (
                  <p className="text-center">
                    Inscrivez-vous gratuitement pour profiter pleinement des
                    offres du plateforme{" "}
                  </p>
                )}
                {!stepOne ? null : (
                  <p className="text-start">(*) : champs obligatoire </p>
                )}
              </div>

              {!stepOne ? (
                <div className="row justify-content-center">
                  <div className="col-12 d-flex justify-content-center flex-column align-items-center">
                    <h3 className="">Vous appartenez à quel type ?</h3>
                    <div className="my-3">
                      <select
                        className={
                          "form-control " + (error.type_org && "is-invalid")
                        }
                        name="type_org"
                        value={formValues.type_org}
                        onChange={handleChange}
                      >
                        <option>
                          choisir ici le type auquel vous appartenez...
                        </option>
                        <option value="Auto-écoles">Auto-écoles</option>
                        <option value="CIM">CIM</option>
                        <option value="Examinateurs">Centre d'examen</option>
                        <option value="ATT">ATT</option>
                        <option value="CIM">CIM</option>
                        <option value="PTF">PTF</option>
                        <option value="examinateur_agent">Examinateur</option>
                        <option value="Moniteur">Moniteur</option>
                      </select>
                    </div>
                    <div className="d-flex flex-column my-3">
                    <p className="text-muted text-center">
                      Vous avez déjà un compte,{" "}
                      <Link
                        to="/login"
                        className="text-decoration-none text-primary"
                      >
                        Connectez-vous
                      </Link>
                    </p>
                    {/* <Link to={"/"} className="fs-5 text-end text-decoration-none text-secondary">
                     <FontAwesomeIcon icon={faHomeAlt} className="me-2" /> Aller à la page d'accueil
                    </Link> */}
                  </div>
                    {/* <Link to={"/"} className="fs-5 text-end text-decoration-none text-secondary align-self-end">
                     <FontAwesomeIcon icon={faHomeAlt} className="me-2" /> Aller à la page d'accueil
                    </Link> */}
                  </div>
                </div>
              ) : // eslint-disable-next-line react/jsx-pascal-case
              formValues.type_org === "examinateur_agent" ? (
                <Examinateur_Sign handleVide={handleVide} />
              ) : formValues.type_org === "Auto-écoles" ? (
                <AutoEcole_Sign handleVide={handleVide} />
              ) : formValues.type_org === "CIM" ? (
                <Cim_Sign handleVide={handleVide} />
              )  : formValues.type_org === "Moniteur" ? (
                <Moniteur_Sign autoecole={dataRedux} handleVide={handleVide} />
              ) : (
                <Form className="w-100" onSubmit={handleSubmit}>
                  {step === 1 && (
                    <>
                      <div className="w-100">
                        <div className="">
                          <div className="">
                            <label className="form-label">
                              Nom de votre organisme
                            </label>
                            <input
                              className={
                                "form-control " +
                                (error.nom_org && "is-invalid")
                              }
                              name="nom_org"
                              value={formValues.nom_org}
                              onChange={handleChange}
                              type="text"
                              placeholder="Saisir nom "
                            />
                            {error.nom_org ? (
                              <div className="invalid-feedback">
                                {error.nom_org}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="my-3">
                            <label className="form-label">Email</label>
                            <input
                              className={
                                "form-control " +
                                (error.email_org && "is-invalid")
                              }
                              name="email_org"
                              value={formValues.email_org}
                              onChange={handleChange}
                              type="email"
                              placeholder="Saisir email"
                            />
                            {error.email_org ? (
                              <div className="invalid-feedback">
                                {error.email_org}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="row g-3">
                            <div className="col-12 col-lg-6">
                              <label className="form-label">Adresse</label>
                              <input
                                className={
                                  "form-control " +
                                  (error.adresse_org && "is-invalid")
                                }
                                name="adresse_org"
                                value={formValues.adresse_org}
                                onChange={handleChange}
                                type="text"
                                placeholder="Saisir adresse"
                              />
                              {error.adresse_org ? (
                                <div className="invalid-feedback">
                                  {error.adresse_org}
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                            <div className="col-12 col-lg-6">
                              <label className="form-label">ville</label>
                              <input
                                className={
                                  "form-control " +
                                  (error.ville_org && "is-invalid")
                                }
                                name="ville_org"
                                value={formValues.ville_org}
                                onChange={handleChange}
                                type="text"
                                placeholder="Saisir ville"
                              />
                              {error.ville_org ? (
                                <div className="invalid-feedback">
                                  {error.ville_org}
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                            <div className="col-12 col-lg-6">
                              <label className="form-label">Région</label>
                              <Select
                                options={regions}
                                onChange={(select) =>
                                  setFormValues({
                                    ...formValues,
                                    province_org: select.value,
                                  })
                                }
                                placeholder="Selectionnez un région"
                              />
                              {error.province_org ? (
                                <div className="text-danger">
                                  {error.province_org}
                                </div>
                              ) : (
                                <></>
                              )}
                              {/* <input
                                className={
                                  "form-control " +
                                  (error.province_org && "is-invalid")
                                }
                                name="province_org"
                                value={formValues.province_org}
                                onChange={handleChange}
                                type="text"
                                placeholder="Saisir région"
                              /> */}
                              {error.province_org ? (
                                <div className="invalid-feedback">
                                  {error.province_org}
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                            <div className="col-12 col-lg-6">
                              <label className="form-label">Code postal</label>
                              <input
                                className={
                                  "form-control " +
                                  (error.cp_org && "is-invalid")
                                }
                                name="cp_org"
                                value={formValues.cp_org}
                                onChange={handleChange}
                                type="text"
                                placeholder="Saisir code postal"
                              />
                              {error.cp_org ? (
                                <div className="invalid-feedback">
                                  {error.cp_org}
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                            <div className="col-12">
                              <label className="form-label">Pays </label>
                             <Select
                              value={formValues?.payselected || []}
                              options={Pays}
                              onChange={(select) => {
                                setFormValues({
                                  ...formValues,
                                  pays_org: select.value,
                                  payselected: select,
                                });
                              }}
                              placeholder="Selectionnez un pays"
                            />
                              {error.pays_org ? (
                                <div className="invalid-feedback">
                                  {error.pays_org}
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center my-3 gap-3">
                        <ConfirmationAnnulation handleVide={handleVide} />
                        <Button
                          variant="primary"
                          className="py-2 rounded-2"
                          type="button"
                          onClick={() => verif()}
                        >
                          Étape suivante
                        </Button>
                      </div>
                    </>
                  )}
                  {step === 2 && (
                    <>
                      <div className="w-100">
                        <div className="">
                          <div className="row g-3 align-items-center">
                            <div className="col-12">
                              <label className="form-label">Site web</label>
                              <input
                                className={
                                  "form-control " +
                                  (error.web_org && "is-invalid")
                                }
                                name="web_org"
                                value={formValues.web_org}
                                onChange={handleChange}
                                type="text"
                                placeholder="Saisir Site web"
                              />
                              {error.web_org ? (
                                <div className="invalid-feedback">
                                  {error.web_org}
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                            <div className="col-12">
                              <label className="form-label">
                                Telephone (*)
                              </label>
                              <InputMask
                                onKeyUp={(e) => {
                                  console.log(e.target.value);
                                  if (e.target.value === "") {
                                    setError({
                                      ...error,
                                      tel_org: [
                                        "le champs telephone est requis",
                                      ],
                                    });
                                  } else if (e.target.value !== "") {
                                    setError({ ...error, tel_org: [] });
                                  }
                                }}
                                onChange={handleChange}
                                mask="+261 39 99 999 99"
                                type="text"
                                className={
                                  "form-control " +
                                  (error.tel_org?.length > 0 && " is-invalid")
                                }
                                value={formValues.tel_org}
                                name="tel_org"
                                placeholder="Telephone"
                              />

                              {error.tel_org && (
                                <p className="invalid-feedback p-0 m-1">
                                  {error.tel_org}
                                </p>
                              )}
                            </div>
                            <div className="col-12">
                              <div className="">
                                <label className="form-label">Logo </label>
                                <input
                                  className={
                                    "form-control " +
                                    (error.logo_org && "is-invalid")
                                  }
                                  name="logo_org"
                                  id="logo_org"
                                  type="file"
                                  onChange={onImageChange}
                                  accept="image/*"
                                />
                                {error.logo_org && (
                                  <div className="invalid-feedback">
                                    {error.logo_org}
                                  </div>
                                )}
                              </div>
                            </div>
                            {/* <div className="col-4 align-self-end">
                              {image && (
                                <span
                                  className="avatar avatar-md"
                                  style={{
                                    backgroundImage: "url(" + image + ")",
                                  }}
                                ></span>
                              )}
                            </div> */}
                          </div>
                        </div>
                      </div>

                      <div className="d-flex justify-content-center my-3 gap-3">
                        <Button
                          variant="secondary"
                          type="button"
                          className="py-2 rounded-2"
                          onClick={() => setStep(step - 1)}
                        >
                          Étape précédente
                        </Button>{" "}
                        <button
                          type="submit"
                          className={
                            "btn btn-primary py-2 rounded-2 " +
                            (load && "disabled")
                          }
                        >
                          Créer un compte
                        </button>
                      </div>
                    </>
                  )}
                  <div className="d-flex flex-column my-3">
                    <p className="text-muted text-center">
                      Vous avez déjà un compte,{" "}
                      <Link
                        to="/login"
                        className="text-decoration-none text-primary"
                      >
                        Connectez-vous
                      </Link>
                    </p>
                    {/* <Link to={"/"} className="fs-5 text-end text-decoration-none text-secondary">
                     <FontAwesomeIcon icon={faHomeAlt} className="me-2" /> Aller à la page d'accueil
                    </Link> */}
                  </div>
                </Form>
              )}
            </div>
           </div>
          </div>
        </div>
      </div>
    </div>
  );
}
