import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  addExaminateur,
  CATEGORIE_API,
  membre_API,
  organisme_API,
  Register_API,
} from "../../service/config";
import { toast } from "react-toastify";
import mtm from "../../assets/image/mtm.png";
import VerifyEmail from "./VerifyEmail";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretLeft,
  faCaretRight,
  faEye,
  faEyeSlash,
  faHomeAlt,
  faSave,
  faWarning,
} from "@fortawesome/free-solid-svg-icons";
import InputMask from "react-input-mask";
import { Button, Form, Modal } from "react-bootstrap";
import Select from "react-select";
import { regions } from "../../service/auth/UtilApi";
import makeAnimated from "react-select/animated";
import SelectOne from "../../outils/Select/selectCustom";
import ConfirmationAnnulation from "./ConfirmationAnnulation";
import * as Yup from "yup";
import Inpt from "../../outils/Input/Inpt";
import { Image } from "primereact/image";
import SelectFinal from "../../outils/Select/SelectFinal";
import { Pays } from "../../outils/Pays";

export default function Examinateur_Sign(props) {
  const validationSchema = Yup.object().shape({
    nom_memb: Yup.string().required("Ce champs est obligatoire !"),
    prenom_memb: Yup.string().required("Ce champs est obligatoire !"),
    sexe_memb: Yup.string().required("Ce champs est obligatoire !"),
    naiss_memb: Yup.string().required("Ce champs est obligatoire !"),
    cin_memb: Yup.string().required("Ce champs est obligatoire !"),
    nationalite_memb: Yup.string().required("Ce champs est obligatoire !"),
    email_memb: Yup.string()
      .email("Veuillez renseigner un email valide !")
      .required("Ce champs est obligatoire !"),
    tel_memb: Yup.string().required("Ce champs est obligatoire !"),
    adresse_memb: Yup.string().required("Ce champs est obligatoire !"),
    cp_memb: Yup.string().required("Ce champs est obligatoire !"),
    ville_memb: Yup.string().required("Ce champs est obligatoire !"),
    province_memb: Yup.string().required("Ce champs est obligatoire !"),
    pays_memb: Yup.string().required("Ce champs est obligatoire !"),
    photo_memb: Yup.string().required("Ce champs est obligatoire !"),
    fonction_memb: Yup.string().required("Ce champs est obligatoire !"),
    id_categ: Yup.string().required("Ce champs est obligatoire !"),


    
  });
  const animatedComponents = makeAnimated();
  const [options, setOptions] = useState([]);
  const [load, setLoad] = useState(false);
  const [ito, setIto] = useState([]);
  const [stepOne, setStepOne] = useState(false);
  const [listorg, setlistorg] = useState([]);
  const [showdp, setshowdp] = useState(false);
  const [verify, setverify] = useState(false);
  const [loadcateg, setloadcateg] = useState(false);
  const navigate = useNavigate();
  const [util, setUtil] = useState({
    nom_util: "",
    prenom_util: "",
    email: "",
    tel_util: "",
    password: "",
    password_confirmation: "",
  });
  // const [error, setError] = useState([]);
  const [success, setsuccess] = useState([]);

  const [formValues, setFormValues] = useState({
    nom_memb: "",
    prenom_memb: "",
    sexe_memb: "",
    naiss_memb: "",
    cin_memb: "",
    nationalite_memb: "",
    email_memb: "",
    tel_memb: "",
    adresse_memb: "",
    cp_memb: "",
    ville_memb: "",
    province_memb: "",
    pays_memb: "",
    photo_memb: "",
    fonction_memb: "",
    id_categ:"",
    role_memb: "Examinateurs",
   
  });
  const [vide, setvide] = useState({
    nom_memb: "",
    prenom_memb: "",
    cin_memb: "",
    photo_memb: "",
    sexe_memb: "",
    naiss_memb: "",
    nationalite_memb: "",
    fonction_memb: "",
    adresse_memb: "",
    ville_memb: "",
    active_memb: "",
    province_memb: "",
    cp_memb: "",
    pays_memb: "",
    tel_memb: "",
    email_memb: "",
    role_memb: "",
   
  });
  const handleVide = () => {
    setFormValues(vide);
    setStepOne(false);
  };
  const change = (choice) => {
    setIto(choice);
    let text = "";
    var nb = 0;
    text = "";
    choice.forEach((element) => {
      nb++;
      text += element.label + ",";
      if (nb === choice.length) {
        setFormValues({ ...formValues, categ_permis: text });
        setError({ ...error, categ_permis: null });
      }
    });
  };
  const [error, setError] = useState([]);
  const [step, setStep] = useState(1);
  const handleChange = ({ currentTarget }) => {
    const { name, value } = currentTarget;
    setFormValues({ ...formValues, [name]: value });
    setError({ ...error, [name]: null });
    if (name === "type_org") {
      if (value) {
        setStepOne(true);
      } else {
        setStepOne(false);
      }
    }
  };

  const [image, setImage] = useState({
    photo_memb: "",
    scan_capp: "",
  });

  const getAllCateg = async () => {
    setloadcateg(true);
    let getCateg = [];
    await axios
      .get(CATEGORIE_API)
      .then((rep) => {
        console.log(rep);
        const data = rep.data.data;

        data.forEach((element) => {
          getCateg.push({ value: element.id_categ, label: element.categ });
        });
        setloadcateg(false);
      })
      .catch((err) => {
        console.log(err);
        setloadcateg(false);
      });
    setOptions(getCateg);
  };

  const onImageChange = (e) => {
    console.log(e.target.files[0].size);

    if (e.target.files && e.target.files[0]) {
      setImage({
        ...image,
        [e.target.name]: URL.createObjectURL(e.target.files[0]),
      });
      setFormValues({ ...formValues, [e.target.name]: e.target.files[0] });
      setError({ ...error, [e.target.name]: null });
    }
  };
  const verif = async () => {
    setError({});
    const champs = Object.keys(formValues);
    for (let i = 0; i < champs.length; i++) {
      const name_champs = champs[i];
      try {
        await validationSchema
          .validateAt(name_champs, formValues, { abortEarly: true })
          .then(() => {
            if (step == 1 && name_champs == "nationalite_memb") {
              setStep(step + 1);
            } else if (step == 2 && name_champs == "pays_memb") {
              setStep(step + 1);
            } 
          });
      } catch (error) {
        if (error.path === name_champs) {
          setError({ [name_champs]: error.message });
          return;
        }
      }
    }
  };

  const handleSubmit = async () => {
    setLoad(true);
    const apiErrors = {};
    try {
      await axios
        .post(addExaminateur, formValues, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response);
          setLoad(false);
          toast.success("enregistrement reussi !");
          navigate("/login");
          setImage(null);
          setFormValues(vide);
        })
        .catch((error) => {
          console.log(error);
          const msg = error.response.data;
          setLoad(false);
          toast.error("Verifier votre saisi !");
          if (msg) {
           
            let champs = Object.keys(msg);
            for (let i = 0; i < champs.length; i++) {
              const element = champs[i];
              if (msg[element]) {
                apiErrors[element] = msg[element];
              }
            }
          }
          if (error.response.status == 500) {
            toast.error(error.response.data.message);
          }
          if (error.response.status == 401) {
            toast.info(error.response.data.message);
          }
        });
    } catch (error) {
      console.log(error);
      setLoad(false);
    }
    setError(apiErrors);
  };
  const getAllOrganisme = async () => {
    try {
      await axios
        .get(organisme_API + "?fields=id_org,nom_org")
        .then((reponse) => {
          console.log(reponse.data);
          let dataa = reponse.data.data;
          setlistorg(dataa);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

useEffect(() => {
 console.log(formValues)
}, [formValues])

useEffect(() => {
  getAllCateg()
 }, [])

  return (
    <div className="contenu">
      <div className="">
        {step == 1 ? (
          <div className="info-perso">
            <div className="my-3">
              <label className="form-label">Nom</label>
              <input
                className={"form-control " + (error.nom_memb && "is-invalid")}
                name="nom_memb"
                value={formValues.nom_memb}
                onChange={handleChange}
                type="text"
                placeholder="Saisir ici votre nom "
              />
              {error.nom_memb ? (
                <div className="invalid-feedback">{error.nom_memb}</div>
              ) : (
                <></>
              )}
            </div>
            <div className="my-3">
              <label className="form-label">Prenom</label>
              <input
                className={
                  "form-control " + (error.prenom_memb && "is-invalid")
                }
                name="prenom_memb"
                value={formValues.prenom_memb}
                onChange={handleChange}
                type="text"
                placeholder="Saisir ici votre prenom"
              />
              {error.prenom_memb ? (
                <div className="invalid-feedback">{error.prenom_memb}</div>
              ) : (
                <></>
              )}
            </div>

            <div className="my-3">
              <label className="form-label">Sexe</label>
              <select
                className={"form-control " + (error.sexe_memb && "is-invalid")}
                name="sexe_memb"
                value={
                  formValues.sexe_memb === true
                    ? "1"
                    : formValues.sexe_memb === false
                    ? "0"
                    : formValues.sexe_memb === ""
                    ? formValues.sexe_memb
                    : formValues.sexe_memb
                }
                onChange={handleChange}
              >
                <option>choisir sexe ...</option>
                <option value={0}>Femme</option>
                <option value={1}>Homme</option>
              </select>
              {error.sexe_memb ? (
                <div className="invalid-feedback">{error.sexe_memb}</div>
              ) : (
                <></>
              )}
            </div>
            <div className="my-3">
              <label className="form-label">Date de naissance</label>
              <input
                className={"form-control " + (error.naiss_memb && "is-invalid")}
                name="naiss_memb"
                value={formValues.naiss_memb}
                onChange={handleChange}
                type="date"
                placeholder="Chosir date"
              />
              {error.naiss_memb ? (
                <div className="invalid-feedback">{error.naiss_memb}</div>
              ) : (
                <></>
              )}
            </div>
            <div className="row my-3">
              <div className="col-12 col-lg-6">
                <label className="form-label">
                  Carte d'Identité Nationale (CIN){" "}
                </label>
                <InputMask
                  onKeyUp={(e) => {
                    console.log(e.target.value);
                    if (e.target.value === "") {
                      setError({
                        ...error,
                        cin_memb: ["le champs cin est requis"],
                      });
                    } else if (e.target.value !== "") {
                      setError({ ...error, cin_memb: [] });
                    }
                  }}
                  onChange={handleChange}
                  type="text"
                  className={
                    "form-control " +
                    (error.cin_memb?.length > 0 && " is-invalid")
                  }
                  value={formValues.cin_memb}
                  name="cin_memb"
                  placeholder="CIN"
                />

                {error.cin_memb &&
                  error.cin_memb.map((element) => (
                    <p key={element} className="invalid-feedback p-0 m-1">
                      {element}
                    </p>
                  ))}
              </div>
              <div className="col-12 col-lg-6">
                <label className="form-label">Nationalité</label>
                <input
                  className={
                    "form-control " + (error.nationalite_memb && "is-invalid")
                  }
                  name="nationalite_memb"
                  value={formValues.nationalite_memb}
                  onChange={handleChange}
                  type="text"
                  placeholder="Saisir ici votre nationalité"
                />
                {error.nationalite_memb ? (
                  <div className="invalid-feedback">
                    {error.nationalite_memb}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        ) : step == 2 ? (
          <div className="info-coordonne">
            <div className="my-3">
              <label className="form-label">Email</label>
              <input
                className={"form-control " + (error.email_memb && "is-invalid")}
                name="email_memb"
                value={formValues.email_memb}
                onChange={handleChange}
                type="email"
                placeholder="Saisir ici votre email"
              />
              {error.email_memb ? (
                <div className="invalid-feedback">{error.email_memb}</div>
              ) : (
                <></>
              )}
            </div>
            <div className="my-3">
              <label className="form-label">Telephone (*)</label>
              <InputMask
                onKeyUp={(e) => {
                  console.log(e.target.value);
                  if (e.target.value === "") {
                    setError({
                      ...error,
                      tel_memb: ["le champs telephone est requis"],
                    });
                  } else if (e.target.value !== "") {
                    setError({ ...error, tel_memb: [] });
                  }
                }}
                onChange={handleChange}
                mask="+261 39 99 999 99"
                type="text"
                className={
                  "form-control " +
                  (error.tel_memb?.length > 0 && " is-invalid")
                }
                value={formValues.tel_memb}
                name="tel_memb"
                placeholder="Telephone"
              />

              {error.tel_memb && (
                <p className="invalid-feedback p-0 m-1">{error.tel_memb}</p>
              )}
            </div>
            <div className="my-3 row">
              <div className="col-12 col-lg-8">
                <label className="form-label">Adresse</label>
                <input
                  className={
                    "form-control " + (error.adresse_memb && "is-invalid")
                  }
                  name="adresse_memb"
                  value={formValues.adresse_memb}
                  onChange={handleChange}
                  type="text"
                  placeholder="Saisir ici votre adresse du membre"
                />
                {error.adresse_memb ? (
                  <div className="invalid-feedback">{error.adresse_memb}</div>
                ) : (
                  <></>
                )}
              </div>
              <div className="col-12 col-lg-4">
                <label className="form-label">Code postal</label>
                <input
                  className={"form-control " + (error.cp_memb && "is-invalid")}
                  name="cp_memb"
                  value={formValues.cp_memb}
                  onChange={handleChange}
                  type="number"
                  placeholder="votre code postal"
                />
                {error.cp_memb ? (
                  <div className="invalid-feedback">{error.cp_memb}</div>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-lg-6">
                <label className="form-label">Ville</label>
                <input
                  className={
                    "form-control " + (error.ville_memb && "is-invalid")
                  }
                  name="ville_memb"
                  value={formValues.ville_memb}
                  onChange={handleChange}
                  type="text"
                  placeholder="Saisir ici votre ville"
                />
                {error.ville_memb ? (
                  <div className="invalid-feedback">{error.ville_memb}</div>
                ) : (
                  <></>
                )}
              </div>
              <div className="col-12 col-lg-6">
                <label className="form-label">Région</label>
                {/* <SelectOne
                  options={regions}
                  load={false}
                  choix={formValues.province_memb}
                  placeholder="choix région"
                  setFormValues={setFormValues}
                  formValues={formValues}
                  setError={setError}
                  error={error}
                  name={"province_memb"}
                /> */}
                 <Select
                  options={regions}
                  value={[
                    {
                      value: formValues.province_memb,
                      label: formValues.province_memb,
                    },
                  ]}
                  onChange={(select) => {
                    setFormValues({
                      ...formValues,
                      province_memb: select.value,
                    });
                    setError([]);
                  }}
                  placeholder="Selectionnez un région"
                />
                {error.province_memb ? (
                  <div className="invalid-feedback">{error.province_memb}</div>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="my-3">
              <label className="form-label">Pays</label>
              <Select
                              value={formValues?.payselected || []}
                              options={Pays}
                              onChange={(select) => {
                                setFormValues({
                                  ...formValues,
                                  pays_memb: select.value,
                                  payselected: select,
                                });
                              }}
                              placeholder="Selectionnez un pays"
                            />
              {error.pays_memb ? (
                <div className="invalid-feedback">{error.pays_memb}</div>
              ) : (
                <></>
              )}
            </div>
          </div>
        ) : (
          <div className="info-fonction-role">
            <div className="row align-items-center">
              <div className="col-12 col-lg-8">
                <div className="">
                  <label className="form-label">PHOTO </label>
                  <input
                    className={
                      "form-control " + (error.photo_memb && "is-invalid")
                    }
                    name="photo_memb"
                    id="photo_memb"
                    type="file"
                    onChange={onImageChange}
                    accept="image/*"
                  />
                  {error.photo_memb && (
                    <div className="invalid-feedback">{error.photo_memb}</div>
                  )}
                </div>
              </div>
              <div className="col-12 col-lg-4 align-self-end">
                {image.photo_memb && (
                  <span
                    className="avatar avatar-md"
                    style={{ backgroundImage: "url(" + image.photo_memb + ")" }}
                  ></span>
                )}
              </div>
            </div>
            <div className="my-3">
              <label className="form-label">Fonction</label>
              <select
                className={
                  "form-control " + (error.fonction_memb && "is-invalid")
                }
                name="fonction_memb"
                value={formValues.fonction_memb}
                onChange={handleChange}
              >
                <option>choisir fonction ...</option>
                <option value="directeur">directeur</option>
                <option value="secrétaire">secrétaire</option>
                <option value="Moniteur">moniteur</option>
                <option value="Autre">Autre</option>
              </select>
              {error.fonction_memb ? (
                <div className="invalid-feedback">{error.fonction_memb}</div>
              ) : (
                <></>
              )}
            </div>
            <div className="my-3">
            <label className="form-label">Catégories permis</label>
            <SelectFinal
                  options={options}
                  load={false}
                  value={"value"}
                  label={"label"}
                  choix={""}
                  placeholder="choix catégorie"
                  setFormValues={setFormValues}
                  formValues={formValues}
                  setError={setError}
                  error={error}
                  name={"id_categ"}
                  max={7}
                />
            </div>
          
          </div>
        )}
      </div>
      <div className="button d-flex justify-content-center gap-2 my-3">
        {step > 1 ? (
          <Button
            variant="secondary"
            className="py-2 rounded-2"
            disabled={step > 1 ? false : true}
            onClick={() => setStep(step - 1)}
          >
            <FontAwesomeIcon icon={faCaretLeft} className="me-2" />
            précedent
          </Button>
        ) : (
          <ConfirmationAnnulation handleVide={props.handleVide} />
        )}

        {step < 3 ? (
          <Button
            variant="success"
            className="py-2 rounded-2"
            disabled={step < 3 ? false : true}
            onClick={() => verif()}
          >
            suivant
            <FontAwesomeIcon icon={faCaretRight} className="ms-2" />
          </Button>
        ) : (
          <Button
            variant="primary"
            className="py-2 rounded-2"
            onClick={() => step >=3 ? handleSubmit() : verif()}
            disabled={load}
          >
            <FontAwesomeIcon icon={faSave} className="me-2" />
            Enregistrer
          </Button>
        )}
      </div>
      <div className="d-flex flex-column my-3">
        <p className="text-muted text-center">
          Vous avez déjà un compte,{" "}
          <Link to="/login" className="text-decoration-none text-primary">
            Connectez-vous
          </Link>
        </p>
      
      </div>
    </div>
  );
}
